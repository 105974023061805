import Swal from 'sweetalert2'

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    }
});

Toast.success = (message) => {
    Toast.fire({
        icon: "success",
        title: "Sucesso!",
        text: message
    });
}

Toast.error = (message) => {
    Toast.fire({
        icon: "error",
        title: "Erro!",
        text: message
    });
}

Toast.info = (message) => {
    Toast.fire({
        icon: "info",
        title: "Informação",
        text: message
    });
}

Toast.warning = (message) => {
    Toast.fire({
        icon: "warning",
        title: "Atenção!",
        text: message
    });
}

export default Toast