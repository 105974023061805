import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

// contexts
import { EmpresaProvider } from './contexts/EmpresaContext.js';
import { AuthProvider } from './contexts/AuthContext.js';

// pages
import Rifas from './pages/Rifas.js';
import Home from './pages/Home.js';
import MeusNumeros from './pages/MeusNumeros.js';
import Contato from './pages/Contato.js';
import Ganhadores from './pages/Ganhadores.js';
import Promocoes from './pages/Promocoes.js';

// components
import Footer from './components/footer/Footer.js';
import Menu from "./components/menu/Menu.js";

function App() {

  return (
    <BrowserRouter>
      <EmpresaProvider>
        <AuthProvider>
          <Menu/>
          <Routes>
            <Route path='/' element={<Home/>}></Route>
            <Route path='/rifa/:slug' element={<Rifas/>}></Route>
            <Route path='/meus-numeros' element={<MeusNumeros/>}></Route>
            <Route path='/contato' element={<Contato/>}></Route>
            <Route path='/ganhadores' element={<Ganhadores/>}></Route>
            
            <Route path='/promocoes' element={<Promocoes/>}></Route>
            <Route path='/promocao/:slug' element={<Promocoes/>}></Route>

            <Route path='*' element={<Navigate to='/'/>}></Route>
          </Routes>
          <Footer/>
        </AuthProvider>
      </EmpresaProvider>
    </BrowserRouter>
  );
}

export default App;