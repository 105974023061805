// EmpresaContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

import { api } from '../apis/connect';

const EmpresaContext = createContext();

export const useEmpresa = () => {
    return useContext(EmpresaContext);
};

export const EmpresaProvider = ({ children }) => {
    const [empresa, setEmpresa] = useState(null);

    useEffect(() => {

        let meta_empresa = document.querySelector('meta[name="empresa"]');

        if (meta_empresa && meta_empresa.content) {
            setEmpresa(JSON.parse(atob(meta_empresa.content)));
            return;
        } else {

            process.env.REACT_APP_EMPRESA && api.empresa.get(process.env.REACT_APP_EMPRESA).then(res => {
    
                if (res?.error) {
                    return
                }
    
                setEmpresa(res);
    
            })

        }

    }, []);

    const empresaContextValue = {
        empresa
    };

    return (
        <EmpresaContext.Provider value={empresaContextValue}>
            {children}
        </EmpresaContext.Provider>
    );
};
