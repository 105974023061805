import React, {useEffect, useState} from 'react';

import './modalpagamento.css';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import CircularProgress from '@mui/material/CircularProgress';
import InputGroup from 'react-bootstrap/InputGroup';

import ReactDOM from 'react-dom';
import {QRCodeSVG} from 'qrcode.react';

import LoadingSplash from '../LoadingSplash/LoadingSplash';

import PixIcon from '@mui/icons-material/Pix';
import PaidIcon from '@mui/icons-material/Paid';

import Toast from '../toast/Toast'

import CryptoJS from 'crypto-js';
import md5 from 'md5';

import { useAuth } from '../../contexts/AuthContext';
import { api } from '../../apis/connect';
import ModalNumeroPremiado from '../modalNumeroPremiado/ModalNumeroPremiado';

function ModalPagamento(props) {

    const { usuarioLogado, isUsuarioLogado } = useAuth()
    const [paid, setPaid] = useState(false);
    const [pulling_check, setPullingCheck] = useState(null);
    const [loading, setLoading] = useState(true);

    const [showModalPremio, setShowModalPremio] = useState(false);
    const [numerosPremiados, setNumerosPremiados] = useState([]);

    const [infos, setInfos] = useState({
        nome: null,
        celular: null,
        email: null,
        cpf: null,
        rifa: null,
        valor: null,
        quant: null,
        qrcode: null,
        copia_e_cola: null
    });

    useEffect(() => {
        
        var dados = {};
            
        if (!isUsuarioLogado() && props.show) {

            Toast.error('Você precisa estar logado para continuar');

            props.onHide();

        } else if (props.infos?.rifa && props.show) {

            dados = {
                nome: usuarioLogado.nome,
                celular: usuarioLogado.celular,
                email: usuarioLogado.email,
                cpf: usuarioLogado.cpf,
                rifa: props.infos.rifa.nome,
                valor: formatarParaReal(props.infos.valor),
                quant: props.infos.quant
            }

            if (dados.quant !== infos?.quant) {

                setLoading(true);

                let eventID = md5(Date.now().toString()+usuarioLogado.celular);

                // busca code como parametro na url
                const urlParams = new URLSearchParams(window.location.search);
                const code = urlParams.get('code');

                api.cliente.pagamento.create(props.infos.rifa.id, usuarioLogado.id, props.infos.quant, eventID, code).then((response) => {
    
                    if (response?.error) {
                        Toast.error(response.error);
                        return
                    };

                    dados = {
                        ...dados,
                        qrcode: response?.base64 ? 'data:image/png;base64,' + response.base64 : '',
                        copia_e_cola: response.qrcode
                    }

                    setInfos(dados);
                    setLoading(false);

                    if (pulling_check) {
                        clearInterval(pulling_check);
                    }

                    setPullingCheck(setInterval(() => {

                        api.cliente.pagamento.check(response.id).then((res) => {

                            if (res.paid && res.numeros) {

                                setPaid(true);
                                clearInterval(pulling_check);

                                eval(`

                                    try {

                                        if (PIXEL_GOOGLE && '${props.infos.rifa.empresa.seo.pixel_google_label}') {
                                            gtag('event', 'conversion', {
                                                'send_to': '${props.infos.rifa.empresa.seo.pixel_google_id}/${props.infos.rifa.empresa.seo.pixel_google_label}',
                                                'value': ${props.infos.valor},
                                                'currency': 'BRL',
                                                'transaction_id': ''
                                            });
                                        }
    
                                        if (PIXEL_META) {
                                            fbq('track', 'Purchase', {value: ${props.infos.valor}, currency: 'BRL'}, {eventID: '${eventID}'})
                                        }

                                        if (PIXEL_TIKTOK) {

                                            ttq.identify({
                                                "email": "${CryptoJS.SHA256(usuarioLogado.email).toString()}",
                                                "phone_number": "${CryptoJS.SHA256('+55'+usuarioLogado.celular).toString()}",
                                                "external_id": "${CryptoJS.SHA256(usuarioLogado.id).toString()}"
                                            });

                                            ttq.track('CompletePayment', {
                                                "contants": [
                                                    {
                                                        "content_id": ${props.infos.rifa.id},
                                                        "content_name": "${props.infos.rifa.nome}",
                                                        "content_type": "product",
                                                        
                                                    }
                                                ],
                                                "currency": "BRL",
                                                "value": ${props.infos.valor}
                                            });
                                        }

                                    } catch (e) {
                                        console.log('Erro ao enviar pixel: ', e);
                                    }

                                    console.log('Pagamento efetuado com sucesso');

                                `);

                                let numeros_premiados = res.numeros.filter((numero) => {
                                    return numero.premiado === 1
                                });

                                if (numeros_premiados && numeros_premiados.length > 0) {
                                    setNumerosPremiados(numeros_premiados);
                                    setShowModalPremio(true);
                                    props.onHide();
                                } else {

                                    setTimeout(() => {
                                        window.location.href = '/meus-numeros';
                                    }, 5000);

                                }

                                Toast.success('Pagamento efetuado com sucesso!');

                            }

                        });

                    }, 5000));
    
                });

            }

        }

    }, [usuarioLogado, props, isUsuarioLogado]);

    function copyToClipboard(text) {
        navigator.clipboard.writeText(text).then(() => {
            Toast.success('Texto copiado para a área de transferência');
        }).catch((err) => {
            Toast.error('Erro ao copiar texto para a área de transferência');
        });
    }

    function formatarParaReal(numero) {

        const formatoReal = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });

        return formatoReal.format(numero);
    }

    function formatarNumeroTelefone(numero) {
        if (!numero) return '';
        return numero.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    }

    let handlerHideModalPremio = () => {
        setShowModalPremio(false);
        window.location.href = '/meus-numeros';
    }

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Efetuar pagamento
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div class='pay text-center'>
                        <h5><PixIcon className='payic'/>Reservar números usando Pix</h5>

                        <div class="qrcode">
                            {infos?.qrcode ? <img src={infos.qrcode} alt="QR Code" /> : <QRCodeSVG value={infos.copia_e_cola} size={200} />}
                        </div>

                        <InputGroup className="mb-3">
                            <Form.Control
                            placeholder='Pix copia e cola'
                            value={infos.copia_e_cola}
                            />
                            <InputGroup.Text id="basic-addon1" className='copiaecola' onClick={() => {copyToClipboard(infos.copia_e_cola)}}>Clique para copiar</InputGroup.Text>
                        </InputGroup>

                    </div>

                    <div class='instrucoes mt-4 mb-3 text-center'>
    
                            <div class="">
                                <div class="pag-instrucoes"><div class='num'>1</div> <div>Abra o aplicativo de seu banco e selecione a opção de pagamento por Pix</div></div>
                                <div class="pag-instrucoes"><div class='num'>2</div> <div>Escaneie o QR Code ou utilize a opção Pix Copia e Cola</div></div>
                                <div class="pag-instrucoes"><div class='num'>3</div> <div>Faça o pagamento e aguarde a confirmação, é rápido!</div></div>
                            </div>

                    </div>

                    <div class='container infos'>

                        <h5 class="text-center">Informações da reserva</h5>

                        <div class="row">
                            {props?.infos?.rifa?.promo_dobro && <div class="pag-infos col-12 mb-3"><span>Promoção 🎁:</span> Dobro de Números</div>}
                            <div class="pag-infos col-6"><span>Rifa:</span> {infos.rifa}</div>
                            <div class="pag-infos col-6"><span>Quantidade:</span> {props?.infos?.rifa?.promo_dobro ? infos.quant*2 : infos.quant} números</div>
                            <div class="pag-infos col-6"><span>Nome:</span> {infos.nome}</div>
                            <div class="pag-infos col-6"><span>Valor:</span> <span class='text-success'>{infos.valor}</span></div>
                            <div class="pag-infos col-12"><span>Celular:</span> {formatarNumeroTelefone(infos.celular)}</div>
                            {infos.email ? <div class="pag-infos col-12"><span>Email:</span> {infos.email}</div> : ''}
                            {infos.cpf ? <div class="pag-infos col-12"><span>CPF:</span> {infos.cpf}</div> : ''}

                        </div>

                    </div>

                    <div class='pay'>
                        { !paid ? 
                            <div class='d-flex flex-row justify-content-center'>
                                <CircularProgress className='inline' color="inherit" /> 
                                <div class='aguardando'>Aguardando pagamento...</div>
                            </div> :

                            <div class='d-flex flex-row justify-content-center'>
                                <PaidIcon className='paidic' color='success' sx={{fontSize: 45}}/> <div class='aguardando'>Pagamento efetuado com sucesso!</div>
                            </div>
                        }
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-dark' onClick={props.onHide}>Fechar</Button>
                </Modal.Footer>

                <LoadingSplash active={loading} absolute={true}/>
                
            </Modal>

            <ModalNumeroPremiado show={showModalPremio} onHide={() => {handlerHideModalPremio()}} numerosPremiados={numerosPremiados} />
        </>
    );
}


export default ModalPagamento;
