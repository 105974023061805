import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'

import '../assets/css/contato.css'

import Toast from '../components/toast/Toast'

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import EmailIcon from '@mui/icons-material/Email';

import MetaTag from '../components/MetaTag/MetaTag';

import {useEmpresa} from '../contexts/EmpresaContext'

function Default() {

    const {empresa} = useEmpresa()

    function formatarTelefone(numero) {
        var numeroLimpo = numero.replace(/\D/g, '');
        var numeroFormatado = numeroLimpo.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        return numeroFormatado;
    }

    return(
        <div class='container mt-3 mt-sm-5'>

            <MetaTag title={`Contatos - ${empresa?.nome}`} />

            <div class='row'>

                <div class="col-sm-7 d-flex justify-content-center mb-5">

                    <div class='p-3 p-sm-0'>
                        <h3>Aqui estão os contatos do organizador!</h3>

                        <div class='mt-5'>

                            {empresa?.whatsapp && <Link to={"https://wa.me/"+empresa.whatsapp} target='_blank' class="d-flex flex-row mb-4 text-contact">
                                <WhatsAppIcon className='contact-icons wppic'/>
                                <div class="mx-3 my-auto">
                                    <strong class='d-block'>WhatsApp: </strong>
                                    {formatarTelefone(empresa.whatsapp)}
                                </div>
                            </Link>}

                            {empresa?.instagram && <Link to={"https://instagram.com/"+empresa.instagram} target='_blank' class="d-flex flex-row mb-4 text-contact">
                                <div className='instaic'><InstagramIcon className='contact-icons'/></div>
                                <div class="mx-3 my-auto">
                                    <strong class='d-block'>Instagram: </strong>
                                    @{empresa.instagram}
                                </div>
                            </Link>}

                            {empresa?.facebook && <Link to={"https://facebook.com/"+empresa.facebook} target='_blank' class="d-flex flex-row mb-4 text-contact">
                                <FacebookIcon className='contact-icons faceic'/>
                                <div class="mx-3 my-auto">
                                    <strong class='d-block'>Facebook: </strong>
                                    @{empresa.facebook}
                                </div>
                            </Link>}

                            {empresa?.x && <Link to={"https://x.com/"+empresa.x} target='_blank' class="d-flex flex-row mb-4 text-contact">
                                <XIcon className='contact-icons' color='dark'/>
                                <div class="mx-3 my-auto">
                                    <strong class='d-block'>X: </strong>
                                    @{empresa.x}
                                </div>
                            </Link>}

                            {empresa?.email_contato && <Link to={"mailto:"+empresa.email_contato} target='_blank' class="d-flex flex-row mb-4 text-contact">
                                <EmailIcon className='contact-icons emailic'/>
                                <div class="mx-3 my-auto">
                                    <strong class='d-block'>Email: </strong>
                                    {empresa.email_contato}
                                </div>
                            </Link>}

                        </div>
                    </div>

                </div>

                <div class="col-sm-5 d-flex justify-content-center mb-5">

                    <div class="p-3 p-sm-0">
                        <div class='rf-light'>Já pensou também utilizar do melhor sistema de rifas do mercado?</div>
                        <div>
                            Acesse o site do <Link to="https://rifahit.com/">RifaHit</Link> ou entre em contato conosco através dos canais abaixo:
                        </div>
                        <div class='mt-5 redes row justify-content-center'>
                            <Link to={"https://instagram.com/rifahit.app"} target='_blank' class="insta-icon col-12"><span class="ic-prf"><InstagramIcon/></span><span>Instagram</span></Link>
                            <Link to={"https://wa.me/77991991529"} target='_blank' class="wpp-icon col-12"><span class="ic-prf"><WhatsAppIcon/></span><span>WhatsApp</span></Link>
                        </div>
                    </div>

                </div>

            </div>

            

        </div>
    )
}

export default Default