import React, {useEffect, useState} from 'react';
import Lottie from "lottie-react";

import './modalnumeropremiado.css';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import CircularProgress from '@mui/material/CircularProgress';
import InputGroup from 'react-bootstrap/InputGroup';

import LoadingSplash from '../LoadingSplash/LoadingSplash';

import PixIcon from '@mui/icons-material/Pix';
import PaidIcon from '@mui/icons-material/Paid';

import animationTrofeu from '../../assets/imgs/lottie/trofeu.json';
import animationConfete from '../../assets/imgs/lottie/confete.json';

import Toast from '../toast/Toast'

import { useAuth } from '../../contexts/AuthContext';
import { api } from '../../apis/connect';

function ModalNumeroPremiado(props) {

    const { usuarioLogado, isUsuarioLogado } = useAuth()
    const [loading, setLoading] = useState(false);
    const [numerosPremiados, setNumerosPremiados] = useState([]);
    const [visible, setVisible] = useState('');

    useEffect(() => {

            if (props.numerosPremiados && props.numerosPremiados.length > 0) {
                setNumerosPremiados(props.numerosPremiados);
            }

            setVisible('');

            setTimeout(() => {
                setVisible('d-none');
            }, 5000);


    }, [props.numerosPremiados]);



    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Você encontrou um número premiado! Parabéns! 🎉🎉🎉
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='d-flex justify-content-center flex-column w-100 text-center'>

                <Lottie id="confete" className={'confete mx-auto ' + visible} animationData={animationConfete} loop={2}/>

                <Lottie className='trofeu mx-auto' animationData={animationTrofeu}/>

                <h5 class='premiados-title my-4'>Sua compra possui <strong>{numerosPremiados.length}</strong> número(s) premiado(s)!</h5>
                <div class='premiados-list mb-4'>
                    {numerosPremiados.map((numero) => (
                        <div class='btn m-2 btn-success'>
                            <strong>{numero.numero}</strong>
                            <div class='text-low'>{numero.premio}</div>
                        </div>
                    ))}
                </div>

                <div class='mb-4'>Aguarde o contato da organização para receber seu prêmio!</div>

            </Modal.Body>
            <Modal.Footer>
                <Button className='btn-dark' onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>

            <LoadingSplash active={loading} absolute={true}/>
            
        </Modal>
    );
}


export default ModalNumeroPremiado;
