
const url = process.env.REACT_APP_API;
const url_storage = process.env.REACT_APP_STORAGE;

const api = {

    rifa: {

        get: async (slug) => {
            const response = await fetch(`${url}/rifas/getslug`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    slug: slug
                })
            });
            return response.json();
        },

        fotos: async (id) => {
            const response = await fetch(`${url}/rifas/getfotos`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    rifa: id
                })
            });
            return response.json();
        },

        ranking: async (id) => {
            const response = await fetch(`${url}/rifas/ranking`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    rifa: id
                })
            });
            return response.json();
        },

        premios_ranking: async (id) => {
            const response = await fetch(`${url}/rifas/getPremiosRanking`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    rifa: id
                })
            });
            return response.json();
        },

        promocoes: async (id) => {
            const response = await fetch(`${url}/rifas/getPromocoes`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    rifa: id
                })
            });
            return response.json();
        },

        numeros_premiados: async (id) => {
            const response = await fetch(`${url}/rifas/getNumerosPremiadosCliente`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    rifa: id
                })
            });
            return response.json();
        },

        getByEmpresa: async (empresa) => {
            const response = await fetch(`${url}/rifas/getbyempresa`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    empresa: empresa
                })
            });
            return response.json();
        },

        getNumeros: async (cliente, empresa) => {
            const response = await fetch(`${url}/rifas/getnumeros`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    cliente,
                    empresa
                })
            });
            return response.json();
        },

        getGanhadores: async (empresa) => {
            const response = await fetch(`${url}/rifas/getganhadores`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    empresa
                })
            });
            return response.json();
        },

        getBotoes: async (rifa) => {
            const response = await fetch(`${url}/rifas/getbotoes`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    rifa
                })
            });
            return response.json();
        },

    },

    cliente: {

        create: async (nome, email, celular, cpf, instagram) => {
            const response = await fetch(`${url}/clientes/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    empresa: process.env.REACT_APP_EMPRESA,
                    nome: nome,
                    email: email,
                    celular: celular,
                    cpf: cpf,
                    instagram: instagram
                })
            });
            return response.json();
        },

        exists: async (celular) => {
            const response = await fetch(`${url}/clientes/exists`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    empresa: process.env.REACT_APP_EMPRESA,
                    celular
                })
            });
            return response.json();
        
        },

        pagamento: {

            create: async (rifa, cliente, quantidade, eventID, code) => {
                const response = await fetch(`${url}/pagamentos/create`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        rifa,
                        cliente,
                        quantidade,
                        eventID,
                        code
                    })
                });
                return response.json();
            },

            check: async (id) => {
                const response = await fetch(`${url}/pagamentos/check`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id
                    })
                });
                return response.json();
            }

        }

    },

    empresa: {

        get: async (empresa) => {
            const response = await fetch(`${url}/empresas/getcliente`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    empresa: empresa
                })
            });
            return response.json();
        }

    },

    promocoes: {

        USUN: async (cliente, rifa, instagram) => {
            const response = await fetch(`${url}/promocoes/usun`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    cliente,
                    rifa,
                    instagram
                })
            });
            return response.json();
        }

    }

}

export { url, api, url_storage };