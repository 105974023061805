import React, {useState, useRef} from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import InputMask from 'react-input-mask';

import { api } from '../../apis/connect';

import EmailIcon from '@mui/icons-material/Email';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import FingerprintIcon from '@mui/icons-material/Fingerprint';

import Toast from '../toast/Toast'

import { useAuth } from '../../contexts/AuthContext';

// import LoadingSplash from '../LoadingSplash/LoadingSplash';

function ModalCadastro(props) {

    const {login} = useAuth();

    const [textbutton, setTextButton] = useState('Entrar / Cadastrar');

    const inputname = useRef(null);

    const [loading, setLoading] = useState(false);

    let obrigatorio = {
        "nome": true,
        "telefone": true,
        "email": false,
        "cpf": false 
    }

    let handlerSubmit = (e) => {

        e.preventDefault();

        let dados = {
            "nome": e.target.nome.value,
            "telefone": e.target.telefone.value,
            "email": e.target.email.value,
            "cpf": e.target.cpf.value
        }

        setLoading(true);

        api.cliente.create(dados.nome, dados.email, dados.telefone, dados.cpf).then((response) => {

            if (response?.error) {
                Toast.error(response.error);
                return
            }

            login(response);
            props.onHide();
            executeCallback(true);
            setLoading(false);
            return

        });

    }

    let handlerChange = (e) => {

        api.cliente.exists(e.target.value).then((response) => {
    
                if (response?.id) {
                    setTextButton('Entrar');
                    inputname.current.value = response.nome;
                } else {
                    setTextButton('Entrar / Cadastrar');
                }

        });

    }

    let executeCallback = (arg) => {
        if (props.callback) {
            props.callback(arg);
        }
    }

    return (


        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.titulo}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={(e) => handlerSubmit(e)}>

                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1"><DriveFileRenameOutlineIcon/></InputGroup.Text>
                        <Form.Control
                        name='nome'
                        placeholder={'Nome completo' + (obrigatorio.nome ? '' : ' (opcional)')}
                        aria-label="Nome completo"
                        aria-describedby="basic-addon1"
                        required={obrigatorio.nome ? 'Campo obrigatório' : true}
                        ref={inputname}
                        />
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1"><LocalPhoneIcon/></InputGroup.Text>
                        <Form.Control
                        name='telefone'
                        placeholder={'Telefone' + (obrigatorio.telefone ? '' : ' (opcional)')}
                        aria-label="Telefone"
                        aria-describedby="basic-addon1"
                        onChange={(e) => handlerChange(e)}
                        required={obrigatorio.telefone ? 'Campo obrigatório' : false}
                        as={InputMask}
                        mask="(99) 99999-9999"
                        />
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1"><EmailIcon/></InputGroup.Text>
                        <Form.Control
                        name='email'
                        placeholder={'Email' + (obrigatorio.email ? '' : ' (opcional)')}
                        aria-label="Email"
                        aria-describedby="basic-addon1"
                        required={obrigatorio.email ? 'Campo obrigatório' : false}
                        />
                    </InputGroup>

                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1"><FingerprintIcon/></InputGroup.Text>
                        <Form.Control
                        name='cpf'
                        placeholder={'CPF' + (obrigatorio.cpf ? '' : ' (opcional)')}
                        aria-label="CPF"
                        aria-describedby="basic-addon1"
                        required={obrigatorio.cpf ? 'Campo obrigatório' : false}
                        as={InputMask}
                        mask="999.999.999-99"
                        />
                    </InputGroup>

                    <div class='d-flex justify-content-end'>
                        <Button className='my-2' disabled={loading} variant='success' type='submit'>{textbutton}</Button>
                    </div>

                </Form>
            </Modal.Body>

            

        </Modal>
    );
}


export default ModalCadastro;
