import React from "react";

import { Helmet } from "react-helmet";

function MetaTag(props) {
    
    return (
        <Helmet>
            {props?.title && <title>{props?.title}</title>}
            {props?.description && <meta name="description" content={props?.description?.substr(0,250).replace('\n', ' ')} />}
            {props?.keywords && <meta name="keywords" content={props?.keywords} />}
            {props?.robots && <meta name="robots" content={props?.robots} />}
            {props?.canonical && <link rel="canonical" href={props?.canonical} />}
            {props?.ogTitle && <meta property="og:title" content={props?.ogTitle} />}
            {props?.ogDescription && <meta property="og:description" content={props?.ogDescription?.substr(0,250).replace('\n', ' ')} />}
            {props?.ogImage && <meta property="og:image" content={props?.ogImage} />}
            {props?.ogUrl && <meta property="og:url" content={props?.ogUrl} />}
            {props?.ogType && <meta property="og:type" content={props?.ogType} />}
            {props?.ogSiteName && <meta property="og:site_name" content={props?.ogSiteName} />}
            {props?.twitterTitle && <meta name="twitter:title" content={props?.twitterTitle} />}
            {props?.twitterDescription && <meta name="twitter:description" content={props?.twitterDescription?.substr(0,250).replace('\n', ' ')} />}
            {props?.twitterImage && <meta name="twitter:image" content={props?.twitterImage} />}
            {props?.twitterCard && <meta name="twitter:card" content={props?.twitterCard} />}
            {props?.twitterSite && <meta name="twitter:site" content={props?.twitterSite} />}
            {props?.twitterCreator && <meta name="twitter:creator" content={props?.twitterCreator} />}
        </Helmet>
    );

}

export default MetaTag;