import Carousel from 'react-material-ui-carousel'

import './carouselfun.css'

export function CarouselItem(props) {
    return (
        <img src={props.item.img} alt={props.item.name} className="carousel-img"/>
    )
}

export function CarouselFun(props) {

    return (
        <Carousel
            className="carousel"
            autoPlay={true}
            animation="slide"
            indicators={false}
            fullHeightHover={true}
            cycleNavigation={true}
            swipe={true}
        >
            {
                props?.itens.map( (item, i) => <CarouselItem key={i} item={item} /> )
            }
        </Carousel>

    )
}