import React, {useState, useEffect} from 'react'
import { Link, useParams } from 'react-router-dom'

import '../assets/css/home.css'

import {api, url_storage} from '../apis/connect'

import Toast from '../components/toast/Toast'

import {CarouselFun} from '../components/carousel/CarouselFun'
import LoadingSplash from '../components/LoadingSplash/LoadingSplash';

import {useEmpresa} from '../contexts/EmpresaContext'
import MetaTag from '../components/MetaTag/MetaTag';

function Home() {

    const [loading, setLoading] = useState(true);

    const [data, setData] = useState([])
    const {empresa} = useEmpresa()

    useEffect(() => {
        
        api.rifa.getByEmpresa(empresa?.id).then((response) => {

            if (response?.error) {
                return
            }

            let lista = response.map((rifa) => {

                if (rifa.fotos) {
                    rifa.fotos = rifa.fotos.map((item) => {
                        return {
                            name: item.id,
                            img: url_storage + item.foto
                        }
                    })
                }

                return rifa

            })


            setData(lista)

            setLoading(false)

        })

    }, [empresa])

    return(
        <div class='container mt-3 mt-sm-5'>

            { data && data.map((rifa) => {

                if (!rifa?.active) return null

                return <section key={rifa.id} class="rifa-container row mb-5">

                    <div class="slide col-sm-6">
                        <CarouselFun itens={rifa?.fotos ? rifa.fotos : []}/>
                    </div>

                    <div class="rifa-info col-sm-6 p-sm-5 p-4">
                        <h4>{rifa?.nome}</h4>
                        <p>{rifa?.describe && rifa.describe.length > 150 ? rifa.describe.slice(0, 150) + "..." : rifa?.describe}</p>
                        <div class="mb-3">Por apenas <strong class='text-success'>{rifa?.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} 🔥</strong></div>
                        {rifa?.promo_dobro === 1 && <button class="btn btn-promo m-2">Dobro de números</button>}
                        {!rifa?.finalizada ? <Link to={`/rifa/${rifa?.slug}`} class="btn btn-dark">Participar</Link> : <Link to={`/rifa/${rifa?.slug}`} class="btn btn-danger">Finalizada</Link>}
                    </div>

                </section>

            })}

            <LoadingSplash active={loading}/>
            

        </div>
    )
}

export default Home