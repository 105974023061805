import React, {useState, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';

import './menu.css';

import SemLogo from '../../assets/imgs/menu/semlogo.png';
import MenuIcon from '@mui/icons-material/Menu';
import NumbersIcon from '@mui/icons-material/Numbers';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import DescriptionIcon from '@mui/icons-material/Description';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CloseIcon from '@mui/icons-material/Close';
import RedeemIcon from '@mui/icons-material/Redeem';

import { useAuth } from '../../contexts/AuthContext';
import { useEmpresa } from '../../contexts/EmpresaContext';

import { url_storage } from '../../apis/connect';

import ModalCadastro from '../modalCadastro/ModalCadastro'
import { Groups } from '@mui/icons-material';

function Menu(){

    const {isUsuarioLogado, logout} = useAuth();
    const {empresa} = useEmpresa();

    const meusnumeros = useRef(null);

    const [showModalCad, setShowModalCad] = useState(false);
    const [imagem, setImagem] = useState(SemLogo);

    const [menu, setMenu] = useState(false);

    useEffect(() => {

        if (empresa && empresa.logo) {
            setImagem(url_storage + empresa.logo);
        }

    }, [empresa]);

    let handlerSair = (e) => {
        e.preventDefault();
        logout();
    }

    let handlerMeusNum = (e) => {
        e.preventDefault();
        if (!isUsuarioLogado()) {
            setShowModalCad(true);
            return;
        } 

        window.location.href = e.target.href;
    }

    let handlerMenu = () => {
        setMenu(!menu);
    }

    window.addEventListener('click', function(e){
        const header_ul = document.querySelector('header ul');
        header_ul.classList.toggle('active', menu);
    });

    return(
        <header>
            <Link to='/' className='logo'><img src={imagem} alt="Logo" class="sem-logo"/></Link>
            <ul class="navigation">
                <li onClick={() => handlerMenu()}><Link ref={meusnumeros} to='/meus-numeros' onClick={(e) => handlerMeusNum(e)}><NumbersIcon className='icon' /> Meus números</Link></li>
                <li onClick={() => handlerMenu()}><Link to='/ganhadores'><EmojiEventsIcon className='icon' /> Ganhadores</Link></li>
                {/* <li onClick={() => handlerMenu()}><Link to='/termos-e-politica'><DescriptionIcon className='icon' /> Termos e política</Link></li> */}
                <li onClick={() => handlerMenu()}><Link to='/promocoes'><RedeemIcon className='icon' /> Promoções</Link></li>
                <li onClick={() => handlerMenu()}><Link to='/contato'><AlternateEmailIcon className='icon' /> Contato</Link></li>
                {process.env.REACT_APP_AFILIADOS && <li><Link target='_blank' to={process.env.REACT_APP_AFILIADOS}><Groups className='icon' /> Seja afiliado</Link></li>}
                {isUsuarioLogado() ? <li><Link to='/sair' onClick={(e) => handlerSair(e)}><CloseIcon className='icon' /> Sair</Link></li> : ''}
            </ul>
            {!menu ? <MenuIcon className="toggle-menu" onClick={() => setMenu(!menu)} alt=''></MenuIcon>
            :
            <CloseIcon className="toggle-menu" onClick={() => setMenu(!menu)} alt=''></CloseIcon>}

            <ModalCadastro show={showModalCad} onHide={() => setShowModalCad(false)} titulo='Meus números' callback={() => {
                setTimeout(()=>{
                    meusnumeros.current.click()
                }, 500);
            }}/>
        </header>
    )
}

window.addEventListener('scroll', function() {
    const header = document.querySelector('header');
    header.classList.toggle('sticky', window.scrollY > 0)
});

export default Menu