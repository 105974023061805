import React, {useState, useEffect} from 'react'
import { Link, useParams } from 'react-router-dom'

import '../assets/css/meusnumeros.css'

import {api, url_storage} from '../apis/connect'

import Toast from '../components/toast/Toast'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

import {CarouselFun} from '../components/carousel/CarouselFun'
import LoadingSplash from '../components/LoadingSplash/LoadingSplash';
import MetaTag from '../components/MetaTag/MetaTag';

import { useAuth } from '../contexts/AuthContext';
import {useEmpresa} from '../contexts/EmpresaContext'

function MeusNumeros() {

    const [data, setData] = useState([])

    const [showModalVerNumbers, setShowModalVerNumbers] = useState(false)
    const [showModalExtrato, setShowModalExtrato] = useState(false)
    const [loading, setLoading] = useState(true);
    
    const [ showRifa, setShowRifa ] = useState(false)

    const {empresa} = useEmpresa()
    const {usuarioLogado, isUsuarioLogado} = useAuth()

    useEffect(() => {

        if (!usuarioLogado) {
            return
        }
        
        api.rifa.getNumeros(usuarioLogado?.id, process.env.REACT_APP_EMPRESA).then((response) => {

            if (response?.error) {
                return
            }

            let lista = response.map((rifa) => {

                if (rifa.fotos) {
                    rifa.fotos = rifa.fotos.map((item) => {
                        return {
                            name: item.id,
                            img: url_storage + item.foto
                        }
                    })
                }

                if (rifa.numeros) {
                    rifa.numeros_only = rifa.numeros.map((item) => {
                        return item.numero
                    })
                }

                return rifa

            })

            setData(lista)
            setLoading(false)

            if (lista.length === 0) {
                setTimeout(() => {
                    window.location.href = '/'
                }, 5000)
            }

        })

    }, [usuarioLogado, empresa])

    function handleVerNumbers(rifa) {
        setShowRifa(rifa)
        setShowModalVerNumbers(true)
    }

    function handleExtrato(rifa) {
        setShowRifa(rifa)
        setShowModalExtrato(true)
    }

    return(

        <div class='container mt-3 mt-sm-5'>

            <MetaTag title={'Meus números - '+empresa?.nome} description={empresa?.seo?.describe} keywords={empresa?.seo?.keywords} />

            { data && data?.map((rifa) => {

                return <section key={rifa.id} class="rifa-container row mb-5">

                    <div class="slide col-sm-4">
                        <CarouselFun itens={rifa?.fotos ? rifa.fotos : []}/>
                    </div>

                    <div class="rifa-info col-sm-6 p-sm-4 p-4">
                        <Link className='h3 text-decoration-none d-block' to={'/rifa/'+rifa?.slug}>{rifa?.nome}</Link>
                        <p>{rifa?.describe && rifa.describe.length > 100 ? rifa.describe.slice(0, 100) + "..." : rifa?.describe}</p>
                        <p><strong>Seus números:</strong> {rifa?.numeros_only && rifa.numeros_only.length > 20 ? rifa.numeros_only.join(', ').slice(0, 100) + '...' : rifa.numeros_only.join(', ')}</p>
                        <button class="btn btn-dark m-2" onClick={()=>{handleVerNumbers(rifa)}}>Ver todos os números</button>
                        <button class="btn btn-dark m-2" onClick={()=>{handleExtrato(rifa)}}>Ver pagamentos</button>
                        <Link to={`/rifa/${rifa?.slug}`} class="btn btn-success m-2">Quero mais chances!</Link>
                    </div>

                </section>

            })}

            {data.length === 0 && 
            (
                <>
                    <h3 class='text-center'>Você ainda não comprou nenhum número</h3>
                    <div class='text-center'>Te redirecionaremos em 5 segundos para ver as rifas disponíveis</div>
                </>
            )
            }

            <LoadingSplash active={loading}/>

            <ModalVerNumbers show={showModalVerNumbers} onHide={() => setShowModalVerNumbers(false)} rifa={showRifa} />
            <ModalExtrato show={showModalExtrato} onHide={() => setShowModalExtrato(false)} rifa={showRifa} />

            

        </div>
    )
}


function ModalVerNumbers(props) {

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.rifa?.title ? props.rifa?.title : "Todos os números - " + props?.rifa?.nome}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {props?.rifa?.numeros && props.rifa.numeros.map((numero) => {

                    let zfill = (props.rifa.sonho ? props.rifa.sonho : props.rifa.quant).toString().length
                    let numero_formatted = numero.numero.toString().padStart(zfill, "0")

                    return !(numero.premiado === 1) ? <div class='btn btn-secondary rf-numbers'>{numero_formatted}</div>
                        : 
                    <div class='btn btn-success rf-numbers'>
                        <strong>{numero_formatted}</strong>
                        <div class='text-low'>{numero.premio}</div>
                    </div>

                })}

            </Modal.Body>
            <Modal.Footer>
                <Button className='btn-dark' onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>

            
        </Modal>
    );    

}

function ModalExtrato(props) {

    const [showModalVerNumbers, setShowModalVerNumbers] = useState(false)
    const [showPagamento, setShowPagamento] = useState(false)
    const [parcerias, setParcerias] = useState(false)
    const [cortesias, setCortesias] = useState(false)

    useEffect(() => {

        let existe_parceiro = false
        let existe_cortesia = false

        props?.rifa?.pagamentos && props.rifa.pagamentos.map((pagamento) => {
            if (pagamento.parceiro) {
                existe_parceiro = true
            }

            if (pagamento.cortesia) {
                existe_cortesia = true
            }
        })

        setParcerias(existe_parceiro)
        setCortesias(existe_cortesia)

    }, [props])

    function handlerVerNumbers(pagamento, rifa) {

        let rifa_mod = {
            sonho: rifa.sonho,
            quant: rifa.quant,
            numeros: pagamento.numeros,
            title: 'Números do pagamento #' + pagamento.id
        }

        setShowPagamento(rifa_mod)
        props.onHide()
        setShowModalVerNumbers(true)
    }

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Extrato de pagamentos - {props?.rifa?.nome}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Table responsive striped bordered hover className='text-center'>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Quantidade de números</th>
                                <th>Valor total</th>
                                <th>Data de pagamento</th>
                                {parcerias && <th>Parceiro</th>}
                                {cortesias && <th>Cortesia</th>}
                                <th>Ações</th>
                            </tr>
                        </thead>

                        <tbody>

                            {props?.rifa?.pagamentos && props.rifa.pagamentos.map((pagamento) => {
                                return (
                                
                                <tr>
                                    <td>{pagamento.id}</td>
                                    <td>{pagamento.quant}</td>
                                    <td>{pagamento.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    <td>{pagamento.paid_at ? (new Date(pagamento.paid_at).toLocaleString('pt-BR')) : 'Não se aplica'}</td>
                                    {parcerias && <td>{pagamento.parceiro ? pagamento.parceiro : 'Não se aplica'}</td>}
                                    {cortesias && <td>{pagamento.cortesia ? pagamento.cortesia : 'Não se aplica'}</td>}
                                    <td><button class="btn btn-dark" onClick={() => {handlerVerNumbers(pagamento, props.rifa)}}>Ver números</button></td>
                                </tr>
                                
                                )
                            })}

                        </tbody>

                    </Table>

                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-dark' onClick={props.onHide}>Fechar</Button>
                </Modal.Footer>


                

            </Modal>

            <ModalVerNumbers show={showModalVerNumbers} onHide={() => setShowModalVerNumbers(false)} rifa={showPagamento} />
        </>
    );    

}



export default MeusNumeros