import React, {useState, useEffect} from "react";

import './loadingsplash.css';

import Spinner from 'react-bootstrap/Spinner';

function LoadingSplash(props) {

    const [active, setActive] = useState(false);

    useEffect(() => {

        if (props?.active) {
            setActive(true)
        } else {
            setActive(false)
        }

    }, [props?.active]);

    return <>
    
        {active ? <div class={"loading-splash" + (props?.absolute ? ' loading-abs': '')}>
            <Spinner animation="border" variant="secondary">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div> : ''}

    </>

}

export default LoadingSplash;