import React, { useState, useEffect } from 'react';

import './contagemregressiva.css';

const ContagemRegressiva = ({ dataFinal }) => {
    const calcularTempoRestante = () => {
        const total = Date.parse(dataFinal) - Date.parse(new Date());
        const segundos = Math.floor((total / 1000) % 60);
        const minutos = Math.floor((total / 1000 / 60) % 60);
        const horas = Math.floor((total / (1000 * 60 * 60)) % 24);
        const dias = Math.floor(total / (1000 * 60 * 60 * 24));
        const meses = Math.floor(dias / 30);
        const anos = Math.floor(meses / 12);

        return {
            total,
            anos,
            meses,
            dias,
            horas,
            minutos,
            segundos
        };
    };

    const [tempoRestante, setTempoRestante] = useState(calcularTempoRestante());

    useEffect(() => {
        const intervalo = setInterval(() => {
            setTempoRestante(calcularTempoRestante());
        }, 1000);
        return () => clearInterval(intervalo);
    }, []);

    return (
        <div className="text-center d-flex justify-content-center flex-wrap rh-contagem">
            <div className="btn btn-outline-success d-flex flex-column m-2 px-5 py-3"><b className='mb-2'>Dias</b><span class='fs-5'>{tempoRestante.dias >= 0 ? tempoRestante.dias : '00'}</span></div>
            <div className="btn btn-outline-success d-flex flex-column m-2 px-5 py-3"><b className='mb-2'>Horas</b><span class='fs-5'>{tempoRestante.horas >= 0 ? tempoRestante.horas : '00'}</span></div>
            <div className="btn btn-outline-success d-flex flex-column m-2 px-5 py-3"><b className='mb-2'>Minutos</b><span class='fs-5'>{tempoRestante.minutos >= 0 ? tempoRestante.minutos : '00'}</span></div>
            <div className="btn btn-outline-success d-flex flex-column m-2 px-5 py-3"><b className='mb-2'>Segundos</b><span class='fs-5'>{tempoRestante.segundos >= 0 ? tempoRestante.segundos : '00'}</span></div>
        </div>
    );
};

export default ContagemRegressiva;
