import React, {useState, useEffect} from 'react';

import Toast from '../toast/Toast'

import './bilhetes.css';
import { api } from '../../apis/connect';


function Bilhetes({onClick, ...props}) {

    const [quant, setQuant] = useState(props?.min);
    const [modify, setModify] = useState(false);
    const [avisoCompraMinima, setAvisoCompraMinima] = useState(false);

    const [botoes, setBotoes] = useState([]);

    let minimo = detectCompraExclusiva()
    if (minimo && props.min !== parseInt(minimo)) {
        props.min = parseInt(minimo)
    }

    function formatarParaReal(numero) {

        const formatoReal = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });

        return formatoReal.format(numero);
    }

    function detectCompraExclusiva() {

        let urls_params = new URLSearchParams(window.location.search)
        let b64_minimo = urls_params.get('compraexclusiva')

        if (b64_minimo) {
            let minimo = atob(b64_minimo)
            return minimo
        }

        return false
    }

    useEffect(()=>{

        props.rifa && (!botoes || botoes?.length <= 0) && api.rifa.getBotoes(props.rifa).then(response => {

            if (!response.error) {
                setBotoes(response)
                response.forEach(botao => {
                    if (botao.is_destaque) {
                        setQuant(parseInt(botao.numbers))
                    }
                })
            }

        })

        if(quant > props.max) {
            setQuant(props.max);
            return;
        }

        if (quant < 0) {
            setQuant(props.min);
            return;
        }

    }, [quant, props.min, props.rifa]);

    let handlerClick = () => {

        if (isNaN(quant)) {
            setQuant(props.min);
            return;
        }

        if (quant < props.min) {
            setQuant(props.min);

            return;

        }

        onClick(handlerPrice(), quant, modify);
    }

    let handlerPrice = () => {

        if (isNaN(quant)) {
            return 0;
        }
        
        var preco = props.price*quant

        if (props?.promo) {

            props.promo.forEach(promocao => {
                if (promocao.quantidade === quant) {
                    preco = promocao.valor
                }
            });

        }

        return preco;
    }

    let handlerChange = (newquant) => {

        if (newquant < props.min) {
            newquant = props.min;

            if (!avisoCompraMinima) {
                setAvisoCompraMinima(true)
                pixelCompraMinima()
            }

            setModify(true);
        }

        setQuant(newquant);
    }

    let pixelCompraMinima = () => {

        eval(`

            try {
                if (PIXEL_META) {
                    fbq('trackCustom', 'CompraMinima');
                }
            } catch (error) {}

        `);
    }

    return (
        <>
        <div class='ticket'>

            <div class="title-section">Selecione a quantidade de números</div>

            <div class="bilhetes">

                {/* <div class="btns-bilhetes">
                    <div data-numero="10" onClick={()=>setQuant(quant+10)}>+10</div>
                    <div data-numero="100" onClick={()=>setQuant(quant+100)}>+100</div>
                    <div class="mais-popular" data-numero="1000" onClick={()=>setQuant(quant+1000)}>+1000</div>
                    <div data-numero="10000" onClick={()=>setQuant(quant+10000)}>+10000</div>
                </div> */}

                <div class="btns-bilhetes">
                    {botoes.map((botao, index) => (
                        <div key={index} class={botao.is_destaque ? "mais-popular" : ""} data-numero={botao.numbers} onClick={()=>setQuant(quant+parseInt(botao.numbers))}>{"+"+botao.numbers}</div>
                    ))}
                </div>

                <div class="input-bilhetes">
                    <span class="btn-menos" onClick={()=>handlerChange(quant-1)}>-</span>
                    <input type="number" 
                        min={props.min} 
                        max={props.max} 
                        value={quant} 
                        onBlur={(e)=>{
                            if (!e.target.value || e.target.value <= props.min) {
                                setAvisoCompraMinima(true)
                                pixelCompraMinima()
                            }
                            handlerChange(e.target.value)
                        }} 
                        onChange={(e)=>setQuant(e.target.value)}
                    />
                    <span class="btn-mais" onClick={()=>handlerChange(quant+1)}>+</span>
                </div>

            </div>

            <div class="resultado">
                <div class="label">Valor final:</div>
                <div class="valor">{formatarParaReal(handlerPrice())}</div>
            </div> 

            <div class="btn-comprar" onClick={handlerClick}>Reservar</div>

            {avisoCompraMinima && <div class="small text-muted fst-italic">*Quantidade mínima de números para uma compra é de {props.min}</div>}

        </div>

        
        </>
    )
}


export default Bilhetes;

