// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [usuarioLogado, setUsuarioLogado] = useState(null);

    useEffect(() => {
        const usuario = localStorage.getItem('usuario');
        if (usuario) {
            setUsuarioLogado(JSON.parse(usuario));
        }
    }, []);

    const login = (usuario) => {
        localStorage.setItem('usuario', JSON.stringify(usuario));
        setUsuarioLogado(usuario);
    };

    const logout = () => {
        localStorage.removeItem('usuario');
        setUsuarioLogado(null);
    };

    const isUsuarioLogado = () => {
        return usuarioLogado !== null;
    };

    const authContextValue = {
        usuarioLogado,
        login,
        logout,
        isUsuarioLogado,
    };

    return (
        <AuthContext.Provider value={authContextValue}>
            {children}
        </AuthContext.Provider>
    );
};
