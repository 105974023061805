import React, {useState, useEffect} from 'react'
import { Link, useParams } from 'react-router-dom'

import '../assets/css/rifas.css'

import {api, url_storage} from '../apis/connect'

import Toast from '../components/toast/Toast'

import semfoto from '../assets/imgs/menu/semlogo.png'
import PixIcon from '@mui/icons-material/Pix';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';

import { useAuth } from '../contexts/AuthContext';
import { useEmpresa } from '../contexts/EmpresaContext';

import ModalCadastro from '../components/modalCadastro/ModalCadastro';
import ModalPagamento from '../components/modalPagamento/ModalPagamento';

import {CarouselFun} from '../components/carousel/CarouselFun';
import Bilhetes from '../components/bilhetes/Bilhetes';
import BarraProgress from '../components/BarraProgress/BarraProgress';
import LoadingSplash from '../components/LoadingSplash/LoadingSplash';
import ContagemRegressiva from '../components/ContagemRegressiva/ContagemRegressiva'

import Swal from 'sweetalert2'

function Rifas() {

    const { usuarioLogado, isUsuarioLogado } = useAuth()
    const { empresa } = useEmpresa()

    const { slug } = useParams()

    const [data, setData] = useState(null)
    const [promocoes, setPromocoes] = useState([])
    const [ranking, setRanking] = useState([])
    const [premio_ranking, setPremioRanking] = useState([])
    const [numeros_premiados, setNumerosPremiados] = useState([])
    const [dia_sorteio, setDiaSorteio] = useState(null)

    const [pagamento, setPagamento] = useState(null)

    const [showModalCad, setShowModalCad] = useState(false)
    const [showModalPag, setShowModalPag] = useState(false)
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        let meta_rifa = document.querySelector('meta[name="rifa"]')

        if (meta_rifa && meta_rifa.content) {

            let res = JSON.parse(atob(meta_rifa.content))

            if (res?.error) {
                window.location.href = '/'
                return
            }

            if (res.slug === slug) {
                
                setLoading(false)
    
                let fotos = res.fotos ? res.fotos.map((item) => {
                    return {
                        name: item.id,
                        img: url_storage + item.foto
                    }
                }) : []
    
                setData({...res, fotos})

                if (res?.expire_at) {
                    setDiaSorteio(new Date(res.expire_at))
                }
    
                setLoading(false)
    
                api.rifa.ranking(res.id).then((res) => {
        
                    if (res?.error) {
                        return
                    }
        
                    setRanking(res)
        
                })
    
                api.rifa.premios_ranking(res.id).then((res) => {
        
                    if (res?.error) {
                        return
                    }
        
                    setPremioRanking(res)
        
                })
    
                api.rifa.promocoes(res.id).then((res) => {
                    
                    if (res?.error) {
                        return
                    }
    
                    setPromocoes(res)
                })
    
                api.rifa.numeros_premiados(res.id).then((res) => {
                        
                    if (res?.error) {
                        return
                    }
    
                    setNumerosPremiados(res)
    
                })

                if (res.promo_dobro) {
                    popup_dobro()
                }
    
                return;

            }

        }

        slug && api.rifa.get(slug).then((res) => {

            // if (res?.error || res?.empresa?.id !== empresa?.id) {
            //     window.location.href = '/'
            //     return
            // }

            if (res?.error) {
                window.location.href = '/'
                return
            }

            let fotos = res.fotos ? res.fotos.map((item) => {
                return {
                    name: item.id,
                    img: url_storage + item.foto
                }
            }) : []

            setData({...res, fotos})

            if (res?.expire_at) {
                setDiaSorteio(new Date(res.expire_at))
            }

            setLoading(false)

            res.ranking == true && api.rifa.ranking(res.id).then((res) => {
    
                if (res?.error) {
                    return
                }
    
                setRanking(res)
    
            })

            res.ranking == true && api.rifa.premios_ranking(res.id).then((res) => {
    
                if (res?.error) {
                    return
                }
    
                setPremioRanking(res)
    
            })

            api.rifa.promocoes(res.id).then((res) => {
                
                if (res?.error) {
                    return
                }

                setPromocoes(res)
            })

            api.rifa.numeros_premiados(res.id).then((res) => {
                    
                if (res?.error) {
                    return
                }

                setNumerosPremiados(res)

            })

            if (res.promo_dobro) {
                popup_dobro()
            }

            return

        })

    }, [slug])

    let handlerClick = (valor, quant, modify) => {

        setPagamento({
            "rifa": data,
            "quant": quant,
            "valor": valor,
            "modify": modify,
        })

        if (!isUsuarioLogado()) {
            setShowModalCad(true)
        } else {
            setShowModalPag(true)
        }

    }

    let popup_dobro = () => {

        Swal.fire({
            title: 'Promoção 🎁',
            html: `
            <div class="my-5">
                <div>Comprando qualquer quantidade de números, você DOBRA suas chances, e recebe o DOBRO de números! 🎉🍀</div>
                <div class="text-center mt-3">Corra! Essa promoção é por tempo limitado e acaba HOJE! 🕗🏃</div>
            </div>
            `,
            showCloseButton: true,
            showConfirmButton: false,
            timer: 10000
        })

    }

    return(
        <div id='container' class="mb-4">

            <section class="slide">
                <CarouselFun itens={data?.fotos ? data.fotos : []}/>
            </section>

            <section class="data px-3">
                <h2 class="title">{data?.nome}</h2>
                
                <div class="organizer">
                    <img src={data?.empresa?.foto_perfil ? url_storage + data?.empresa?.foto_perfil : semfoto} alt="" class="img-perfil"/>
                    <div class="perfil">
                        <div class="label-name">Organizado por:</div>
                        <div class="perfil-name">{data?.empresa?.nome}</div>
                        <div class="redes">
                            {data?.empresa?.instagram ? <Link to={"https://instagram.com/"+data.empresa.instagram} target='_blank' class="insta-icon"><span class="ic-prf"><InstagramIcon/></span><span>Instagram</span></Link> : ''}
                            {data?.empresa?.whatsapp ? <Link to={"https://wa.me/"+data.empresa.whatsapp} target='_blank' class="wpp-icon"><span class="ic-prf"><WhatsAppIcon/></span><span>WhatsApp</span></Link> : ''}
                            {data?.empresa?.facebook ? <Link to={"https://facebook.com/"+data.empresa.facebook} target='_blank' class="face-icon"><span class="ic-prf"><FacebookIcon/></span><span>Facebook</span></Link> : ''}
                            {data?.empresa?.x ? <Link to={"https://x.com/"+data.empresa.x} target='_blank' class="x-icon"><span class="ic-prf"><XIcon/></span><span>X</span></Link> : ''}
                        </div>
                    </div>
                </div>

            </section>

            <section class="cardw3 checkout">
                
                {data?.restante > 0 ? <div>
                    {
                    !data?.finalizada ? <Bilhetes rifa={data?.id} max={data?.max ? data.max : data?.restante_real} min={data?.restante < data?.min ? data?.restante : data?.min} default={1000} price={data?.valor} promo={promocoes} onClick={handlerClick}/>
                    :
                    <div>
                        <div class="title-section">Rifa finalizada</div>
                        <div class="text-center">
                            Todos os bilhetes foram reservados, mas fique ligado para as próximas edições.
                        </div>
                    </div>
                    }
                </div>
                :
                <div>
                    <div class="title-section text-danger">Rifa esgotada</div>

                    <div class="text-center">
                        Todos os bilhetes foram reservados, mas fique ligado para as próximas edições.
                    </div>
                </div>
                }

            </section>

            {(promocoes && promocoes.length > 0) && (
            
                <section class="cardw3 d-flex flex-column">
                    <div class="title-section">Promoções</div>

                    <div class="promocoes d-flex justify-content-center flex-wrap">

                        {promocoes.map((item) => {
                                
                                return (
                                    <div class="promocao m-3">
                                        <div class="valor mb-2">🎁 <span class='text-dark'>{item.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span></div>
                                        <div class="quantidade"><strong>{item.quantidade}</strong> números</div>
                                    </div>
                                )
                        })}
                    </div>

                </section>

            )}

            {(data?.expire_at && !isNaN((new Date(data.expire_at)).getDate())) && <section class="cardw3">

                <div class="title-section">Dia do sorteio</div>

                <ContagemRegressiva dataFinal={new Date(data.expire_at)} />

                <div class="text-center mt-5">
                    <b class="d-block">
                        {`${dia_sorteio.getDate()} de ${dia_sorteio.toLocaleString('pt-BR', { month: 'long' })} de ${dia_sorteio.getFullYear()} às ${dia_sorteio.getHours()}:${dia_sorteio.getMinutes()}`}
                    </b>
                    <small>Horário de Brasília</small>
                </div>

            </section>}

            <section class="cardw3 description">
                <div class="title-section">Descrição e Regulamento</div>
                <div class="desc-text">
                    {data?.describe}
                </div>
            </section>

            {data?.porcentagem > 40 && <section class="cardw3">

                <div class="title-section">Progresso</div>

                <BarraProgress value={data?.porcentagem} color="success"/>

                {/* <div class="progresso">
                    <div class="restante">🎫 <span>{data?.restante}</span> bilhetes restantes</div>
                </div> */}

            </section>}

            {data?.ranking == true && ((ranking && ranking.length > 0) || (premio_ranking && premio_ranking.length > 0)) && (
            
                <section class="cardw3 d-flex flex-column">
                    <div class="title-section">Ranking {premio_ranking ? 'e Premios': ''}</div>

                    { premio_ranking && <>
                    
                        <div class="premios">

                            {premio_ranking?.map((item) => {

                                let emoji = item.posicao === 1 ? '🥇' : item.posicao === 2 ? '🥈' : item.posicao === 3 ? '🥉' : '🏅'

                                return (
                                    <div class="premio">
                                        <div class='posicao'>{emoji}</div>
                                        <div class='ms-2 mt-1'>
                                            <div><strong>{item.posicao}º</strong> Lugar</div>
                                            <div class="text-low">{item.premio}</div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>

                    </>}

                    {((ranking && ranking.length > 0) || (premio_ranking && premio_ranking.length > 0)) && <hr class='border-secondary w-50 mx-auto'></hr>}

                    { (ranking && ranking.length > 0) && <div class="ranking">

                        {ranking?.map((item, index) => {

                            let emoji = index === 0 ? '🥇' : index === 1 ? '🥈' : index === 2 ? '🥉' : '🏅'

                            return (
                                <div>
                                    <div class="posicao">{emoji}</div>
                                    <div class="nome">{item.nome}</div>
                                    <div class="qtd"><span>{item.total}</span> números</div>
                                </div>
                            )

                        })}

                    </div>}

                </section>

            )}

            {(data && (numeros_premiados && numeros_premiados.length > 0)) && <section class="cardw3">

                <div class="title-section">Números Premiados</div>

                <div class="d-flex justify-content-center flex-wrap">

                    {numeros_premiados.map((item) => {

                        let zfill = (data.sonho ? data.sonho : data.quant).toString().length
                        let numero = item.numero.toString().padStart(zfill, "0")

                        return <div class={"btn m-2 " + (item?.encontrado ? 'btn-danger' : 'btn-success')}>
                            <strong>{numero}</strong>
                            <div class="text-low">{item.premio}</div>
                        </div>

                    })}

                </div>

            </section>}

            <section class="sorteio-info">

                <div class='cardw3'>
                    <div class="title-section">Sorteio</div>
                    <div>🍀 Loteria Federal</div>
                </div>

                <div class='cardw3'>
                    <div class="title-section">Meio de pagamento</div>
                    <div><PixIcon className='icon-pix'/> Pix</div>
                </div>

            </section>

            <LoadingSplash active={loading}/>

            <ModalCadastro show={showModalCad} onHide={()=>setShowModalCad(false)} titulo='Reservar bilhetes' callback={() => {
                
                if (pagamento.modify) {
                    Toast.warning(`A quantidade mínima de números para uma compra é de ${data.min} números`)
                    return
                }

                setShowModalPag(true)
            }}/>
            <ModalPagamento show={showModalPag} onHide={()=>setShowModalPag(false)} infos={pagamento}/>

            

        </div>
    )
}

export default Rifas