import React, {useState, useEffect} from 'react'
import { Link, useParams } from 'react-router-dom'

import '../assets/css/ganhadores.css'

import {api, url_storage} from '../apis/connect'

import Toast from '../components/toast/Toast'

import {useEmpresa} from '../contexts/EmpresaContext'

import MetaTag from '../components/MetaTag/MetaTag';
import LoadingSplash from '../components/LoadingSplash/LoadingSplash'

function Ganhadores() {

    const [data, setData] = useState([])
    const {empresa} = useEmpresa()

    const [loading, setLoading] = useState(false)

    useEffect(() => {

        setLoading(true)

        empresa?.id && api.rifa.getGanhadores(empresa?.id).then(response => {
            if(!response.error) {
                setData(response)
                setLoading(false)
            }
        })

    }, [empresa])

    return(
        <div class='container mt-3 mt-sm-5'>

            <MetaTag title={`Ganhadores - ${empresa?.nome}`} description={`Confira os sortudos que ganharam as últimas rifas sorteadas`} keywords={`ganhadores, sorteio, rifa, ${empresa?.nome}`} />

            <h3 class='mb-5'>Sortudos das últimas edições 🍀</h3>

            <div>

                <div class='row'>

                    {data && data.length > 0 ? data.map((ganhador) => 

                        <div class="col-sm-6 mb-4">
                            <div class="card d-flex flex-row">
                                <img src={url_storage + ganhador.foto} class="img-winner" alt={ganhador.nome}/>
                                <div class="card-body">
                                    <h5 class="card-title>">{ganhador.rifa.nome}</h5>
                                    <div class="card-text">
                                        <div>Número: {ganhador.numero}</div>
                                        <strong class='text-success'>{ganhador.nome} 🏆</strong>
                                        <div>Cidade: {ganhador.cidade}</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        
                        ) :
                        
                        <div class="col-sm-12 mb-4">
                            <h5>Nenhuma rifa ativa foi finalizada ainda</h5>
                        </div>

                    }

                </div>

            </div>


        <LoadingSplash active={loading} />

        </div>
    )
}

export default Ganhadores