import React, {useState, useEffect, useRef} from 'react'
import { Link, useParams } from 'react-router-dom'

import '../assets/css/promocoes.css'

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import InputMask from 'react-input-mask';

import EmailIcon from '@mui/icons-material/Email';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import Instagram from '@mui/icons-material/Instagram';

import Toast from '../components/toast/Toast'

import {api, url_storage} from '../apis/connect'

import {useEmpresa} from '../contexts/EmpresaContext'
import {useAuth} from '../contexts/AuthContext'
import Swal from 'sweetalert2';
import LoadingSplash from '../components/LoadingSplash/LoadingSplash';
import { CarouselFun } from '../components/carousel/CarouselFun';

function Promocoes() {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const [rifaSelecionada, setRifaSelecionada] = useState(false)

    const {empresa} = useEmpresa()
    const {login} = useAuth()

    const inputname = useRef(null);

    let obrigatorio = {
        "nome": true,
        "telefone": true,
        "email": false,
        "cpf": false,
        "instagram": true
    }

    useEffect(() => {

        document.title = "Promoções Ativas - " + empresa?.nome

        setLoading(true)

        empresa?.id && api.rifa.getByEmpresa(empresa?.id).then(response => {
            if(!response.error) {

                let selecionadas = []

                for (let rifa of response) {

                    if (rifa.fotos) {
                        rifa.fotos = rifa.fotos.map((item) => {
                            return {
                                name: item.id,
                                img: url_storage + item.foto
                            }
                        })
                    }

                    if (rifa.active === 1 && (rifa.promo_dobro === 1 || rifa.usun === 1)) {
                        selecionadas.push(rifa)
                    }
                
                }

                setData(selecionadas)
                setLoading(false)

                if (selecionadas.length === 0) {
                    setTimeout(() => {
                        window.location.href = '/'
                    }, 5000)
                }
            }
        })

    }, [empresa])

    let handlerSubmit = (e) => {

        e.preventDefault();

        let dados = {
            "nome": e.target.nome.value,
            "telefone": e.target.telefone.value,
            "email": e.target.email.value,
            "cpf": e.target.cpf.value,
            "instagram": e.target.instagram.value
        }

        setLoading(true)

        api.cliente.create(dados.nome, dados.email, dados.telefone, dados.cpf).then((response) => {

            if (response?.error) {
                Toast.error(response.error);
                return
            }

            api.promocoes.USUN(response.id, rifaSelecionada?.id, dados.instagram).then((response) => {

                setLoading(false)
                    
                if (response?.error) {
                    Toast.error(response.error);
                    return
                }

                eval(`

                    try {
                        if (PIXEL_META) {
                            fbq('trackCustom', 'PromoUSUN');
                        }
                    } catch (error) {}

                `);

                Swal.fire({
                    icon: 'success',
                    title: 'Parabéns! Você ganhou um número grátis! 🚀🎉',
                    html: `
                    <p>Seu número é ${response.numeros[0].numero}. Boa sorte! 🍀<p>
                    <p><strong>🎉 Aumente suas chances de ganhar! 🚀</strong></p>
                    <p>
                        Lembre-se, quanto mais números, maiores são suas chances. Não perca tempo, adquira mais números da sorte hoje mesmo e aproxime-se do seu sonho! 🍀
                    </p>
                    `,
                    confirmButtonText: 'Quero mais números!',
                    confirmButtonColor: '#198754',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    },
                    didClose: () => {
                        window.location.href = '/rifa/'+rifaSelecionada?.slug
                    }
                });

                setRifaSelecionada(false)

            });

            login(response)

            return

        });

    }

    let handlerChange = (e) => {

        api.cliente.exists(e.target.value).then((response) => {
    
                if (response?.id) {
                    inputname.current.value = response.nome;
                }

        });

    }

    return(
        <>
            {!rifaSelecionada && <div class='container mt-5'>

                { data && data?.map((rifa) => {

                    return <section key={rifa.id} class="rifa-container row mb-5">

                        <div class="slide col-sm-4">
                            <CarouselFun itens={rifa?.fotos ? rifa.fotos : []}/>
                        </div>

                        <div class="rifa-info col-sm-6 p-sm-4 p-4">
                            <Link className='h3 text-decoration-none d-block' to={'/rifa/'+rifa?.slug}>{rifa?.nome}</Link>
                            <p>{rifa?.describe && rifa.describe.length > 100 ? rifa.describe.slice(0, 100) + "..." : rifa?.describe}</p>
                            <p>Clique para participar:</p>
                            {rifa?.promo_dobro === 1 && <Link to={'/rifa/'+rifa?.slug} class="btn m-2 btn-promo">Dobro de números !!!</Link>}
                            {rifa?.usun === 1 && <button onClick={() => {setRifaSelecionada(rifa)}} class="btn m-2 btn-promo">Um seguidor, um número !!!</button>}
                        </div>

                    </section>

                })}

                {data.length === 0 && 
                (
                    <>
                        <h3 class='text-center'>Nenhuma promoção ativa no momento!</h3>
                        <div class='text-center'>Te redirecionaremos em 5 segundos para ver as rifas disponíveis</div>
                    </>
                )
                }

            </div>}

            {rifaSelecionada && <div class='container mt-5'>

                <div class='text-center'>

                    <div class='lead text-uppercase mb-2'>Promoção</div>
                    <h2 class='display-5'>Um seguidor, um número</h2>
                    <p class='text-muted'>Siga nosso perfil no instagram, preencha o formulário e ganhe um número grátis!</p>

                    <div class='d-flex justify-content-center'>
                        <Link className='insta-icon btn btn-primary border-0' to={'https://instagram.com/'+empresa?.instagram} target='_blank'><Instagram className='me-2'/>Siga-nos no Instagram</Link>
                    </div>

                    <div class='row my-5 justify-content-center'>

                        <div class='col-md-6'>
                            <Form onSubmit={(e) => handlerSubmit(e)}>

                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1"><DriveFileRenameOutlineIcon/></InputGroup.Text>
                                    <Form.Control
                                    name='nome'
                                    placeholder={'Nome completo' + (obrigatorio.nome ? '' : ' (opcional)')}
                                    aria-label="Nome completo"
                                    aria-describedby="basic-addon1"
                                    required={obrigatorio.nome ? 'Campo obrigatório' : true}
                                    ref={inputname}
                                    />
                                </InputGroup>

                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1"><LocalPhoneIcon/></InputGroup.Text>
                                    <Form.Control
                                    name='telefone'
                                    placeholder={'Telefone' + (obrigatorio.telefone ? '' : ' (opcional)')}
                                    aria-label="Telefone"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => handlerChange(e)}
                                    required={obrigatorio.telefone ? 'Campo obrigatório' : false}
                                    as={InputMask}
                                    mask="(99) 99999-9999"
                                    />
                                </InputGroup>

                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1"><EmailIcon/></InputGroup.Text>
                                    <Form.Control
                                    name='email'
                                    placeholder={'Email' + (obrigatorio.email ? '' : ' (opcional)')}
                                    aria-label="Email"
                                    aria-describedby="basic-addon1"
                                    required={obrigatorio.email ? 'Campo obrigatório' : false}
                                    />
                                </InputGroup>

                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1"><FingerprintIcon/></InputGroup.Text>
                                    <Form.Control
                                    name='cpf'
                                    placeholder={'CPF' + (obrigatorio.cpf ? '' : ' (opcional)')}
                                    aria-label="CPF"
                                    aria-describedby="basic-addon1"
                                    required={obrigatorio.cpf ? 'Campo obrigatório' : false}
                                    as={InputMask}
                                    mask="999.999.999-99"
                                    />
                                </InputGroup>

                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1"><Instagram/></InputGroup.Text>
                                    <Form.Control
                                    name='instagram'
                                    placeholder={'Seu perfil no instagram' + (obrigatorio.instagram ? '' : ' (opcional)')}
                                    aria-label="Instagram"
                                    aria-describedby="basic-addon1"
                                    required={obrigatorio.instagram ? 'Campo obrigatório' : false}
                                    />
                                </InputGroup>

                                <div class=''>
                                    <Button className='my-2' variant='success' type='submit'>Já fiz tudo, quero meu número!</Button>
                                </div>

                            </Form>
                        </div>

                    </div>


                </div>

                <LoadingSplash active={loading}/>

            </div>}

            <LoadingSplash active={loading}/>
        </>
    )
}

export default Promocoes